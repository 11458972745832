export default {
  components: {},
  computed: {},
  data() {
    return {
      id: "",
      card_name: "",
      name: ""
    };
  },
  created() {},
  mounted() {
    window.addEventListener("message", receiveMessage, false);
    function receiveMessage(event) {
      console.log(event);
      if (event.data.type == "weixin") {
        location.href = event.data.data;
      } else {
        if (event.data.dataType === "URL") {
          location.href = event.data.data;
        } else {
          document.body.innerHTML = event.data.data;
          setTimeout(() => {
            document.forms[0].submit();
          }, 10);
        }
      }
    }
  },
  methods: {}
};